<template>
<div>
    <v-container class="contact_box relative-center">
        <h1 class="fuchi_shadow f-20 mb-5">
            お問合せ
        </h1>
        <v-card class="pa-6">
            <v-form ref="form">
                
                <v-textarea v-model="contact" :rules="requredRules" label="お問合せ内容*" type="text" outlined maxlength="1000" />

                <v-btn color="#E64A19" class="mt-3 mb-2" dark x-large @click="confirm()">
                    お問合せ内容の確認
                </v-btn>
            </v-form>
        </v-card>
    </v-container>
    <v-dialog width="600" v-model="dialog">
        <v-card class="pa-4">
            <h3 class="f-gray mb-6">お問合せ内容の確認</h3>
            <div class="text-left">
              

                <div>
                    <div class="bold mt-3">お問合せ内容*</div>
                    <p v-text="contact" class="mt-1 nbr" />
                    <hr />
                </div>
            </div>
            <div class="text-center mt-10">
                <v-btn color="#E64A19" class="mt-3 mb-2 submit_btn" dark x-large @click="submit()" :class="{noevent:loading==1}">
                    <v-progress-circular v-show="loading==1" indeterminate color="white"></v-progress-circular>
                    <span v-show="loading==0">送信する</span>
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
    <Loading v-show="loading!=0" />
    <Snackbar ref="snack" />
</div>
</template>

<script>
import Zip from "@/mixins/zip"
import Form from "@/mixins/form"
import Snackbar from "@/components/snackbar.vue"

import Loading from "@/components/loading.vue"

import axios from "axios"

export default {
    mixins: [Zip, Form, ],
    data() {
        return {
            dialog: false,
            loading: false,
        }
    },
    components: {
        Loading,
        Snackbar
    },
    methods: {
        async confirm() {
            if (!this.$refs.form.validate()) {
                return false
            }
            this.dialog = true
        },
        async submit() {
            this.loading = 1

            let data = {}

            data["contact"] = this.contact

            const response = await axios.post("/user/contact/send", data)
         if (response.data.ok) {
                this.$refs.snack.snack = true
                this.$refs.snack.message = "送信しました"
                this.$refs.form.reset()
            }else{
                alert("エラーが発生しました")
            }
            this.dialog = false
            this.loading = 0
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/contact.scss";
</style>
